import React, { useContext } from "react";

import { Context } from "../../components/Context";
import styles from "./styles";
import { Typography, Grid, Button } from "@material-ui/core";
import { navigate } from "gatsby";
import { isLoggedIn } from "src/utils/auth";
import { DragContainer } from "./DragContainer";
import Img from "gatsby-image";

const Brand = ({ pageContext }) => {
  const { name } = pageContext;
  const { brandsData, setSelectedBrand } = useContext(Context);
  const filteredData = brandsData.filter(({ data }) => data.Name === name);
  const data = filteredData[0] ? filteredData[0].data : null;

  if (!data) {
    return null;
  }

  const classes = styles();
  const logo = data.Logo ? data.Logo.localFiles[0].childImageSharp.fluid : "";
  const description = data.Description;

  const handleOrder = () => {
    navigate("/home");
    setSelectedBrand(name);
  };

  const renderButton = isLoggedIn() ? (
    <Button onClick={handleOrder} className={classes.button} fullWidth>
      Order
    </Button>
  ) : (
    <Button
      onClick={() => navigate("/request-access")}
      className={classes.button}
      fullWidth
    >
      Request access
    </Button>
  );
  return (
    <>
      <Img
        objectFit="contain"
        fluid={logo}
        alt="brand logo"
        className={classes.logo}
        imgStyle={{ width: "none" }}
      />

      <Grid container spacing={10}>
        <Grid item xs={12} md={8}>
          <Typography variant="body2">{description}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          {renderButton}
        </Grid>

        <DragContainer photos={data.Photos.localFiles} />
      </Grid>
    </>
  );
};
export default Brand;
