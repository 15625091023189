import { makeStyles } from "@material-ui/core";

const maxLeft = 100;
const maxTop = 300;

function getRandomInt(max) {
  return `${Math.floor(Math.random() * Math.floor(max))}px !important`;
}
const position = "absolute !important";
const objectFit = "contain";

const styles = makeStyles((theme) => ({
  logo: {
    marginTop: "3rem !important",
    maxHeight: "5rem",
  },
  button: {
    fontSize: 24,
  },
  image_0: {
    left: getRandomInt(maxTop),
    top: getRandomInt(maxTop),
    position: position,
    width: "200px",
    objectFit: objectFit,

    [theme.breakpoints.up("sm")]: {
      width: "400px",
    },
  },
  image_1: {
    position: position,
    left: getRandomInt(maxLeft),
    top: getRandomInt(maxTop),
    objectFit: objectFit,

    width: "200px",
    [theme.breakpoints.up("sm")]: {
      width: "400px",
    },
  },
  image_2: {
    position: position,
    left: getRandomInt(maxLeft),
    top: getRandomInt(maxTop),
    objectFit: objectFit,

    width: "250px",
    [theme.breakpoints.up("sm")]: {
      width: "500px",
    },
  },
  image_3: {
    position: position,
    left: getRandomInt(maxLeft),
    top: getRandomInt(maxTop),
    objectFit: objectFit,

    width: "220px",
    [theme.breakpoints.up("sm")]: {
      width: "450px",
    },
  },
  image_4: {
    position: position,
    left: getRandomInt(maxLeft),
    top: getRandomInt(maxTop),
    objectFit: objectFit,

    width: "150px",
    [theme.breakpoints.up("sm")]: {
      width: "300px",
    },
  },
  image_5: {
    position: position,
    left: getRandomInt(maxLeft),
    top: getRandomInt(maxTop),
    objectFit: objectFit,

    width: "130px",
    [theme.breakpoints.up("sm")]: {
      width: "260px",
    },
  },
  box: {
    width: "100%",
    height: "100vh",
    position: "relative",
  },
}));

export default styles;
