import React from "react";
import styles from "./styles";
import Draggable from "react-draggable";
import Img from "gatsby-image";

export const DragContainer = ({ photos }) => {
  const classes = styles();
  console.log(photos);
  const mappedPhotos = photos.map(({ childImageSharp, url }, index) => {
    const className = classes[`image_${index}`];
    const alt = `product${index}`;
    const photo = childImageSharp && childImageSharp.fluid;

    return (
      <Draggable key={url}>
        {photo ? (
          <div>
            <Img
              fluid={photo}
              draggable={false}
              className={className}
              imgStyle={{
                objectFit: "contain",
                width: "none",
              }}
            />
          </div>
        ) : (
          <img draggable={false} src={url} className={className} alt={alt} />
        )}
      </Draggable>
    );
  });
  return <div className={classes.box}>{mappedPhotos}</div>;
};
